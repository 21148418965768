//custom.scss

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1024px,
    xxl: 1140px
);

$primary: #FF8B23 !default;
$secondary: #FF8B23 !default;
// $success: $green !default;
// $info: $cyan !default;
// $warning: $yellow !default;
// $danger: $red !default;
// $light: $gray-100 !default;
// $dark: $gray-900 !default;

/* 
Corousel Indicators
*/
.carousel-indicators [data-bs-target] {
    border-radius: 100%;
    width: 8px !important;
    height: 8px !important;
    border-bottom: none;
    border-top: none;
    // background-color: $primary !important;
}

.carousel-inner {
    border-radius: 1.5rem;
}

// .carousel-control-prev-icon {
//     background-image: url("../images/left-arrow.png") !important;
// }

// .carousel-control-next-icon {
//     background-image: url("../images/right-arrow.png") !important;
// }

$darkblue: #172554;

.hovershadow:hover {
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.15);
    cursor: pointer;
    transition: box-shadow 0.4s;
}

.hovershadow-md:hover {
    box-shadow: 0 10px 12px -6px rgb(0 0 0 / 0.15);
    cursor: pointer;
    transition: box-shadow 0.4s;
}

.trip-banner-text {
    border-radius: 1rem;
    background-color: #FFF;
    position: relative;
    top: -3.5rem;
    padding: 1.5rem;
    text-align: center;
    z-index: 3;
    margin: auto;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

@import "../../../node_modules/bootstrap/scss/bootstrap";

// Accordion Button Styles
.accordion-item {
    border: 0;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 10px;
    background: #F8F9FA;
    margin-bottom: 10px;
}

.accordion-button:not(.collapsed) {
    color: #FFF;
    background-color: #FF8B23 !important;
    border-radius: 10px !important;
    box-shadow: 0 10px 12px -6px rgb(0 0 0 / 0.15);
}

.accordion-button:focus {
    border-color: none;
    box-shadow: none;
}

:root {
    scroll-behavior: unset;
}